import React from "react";
import { Box, Container, Typography, Grid, Card, CardContent, CardMedia, Button } from "@mui/material";
import { motion } from "framer-motion";

const guy = "https://storage.googleapis.com/app_darkendimg/data-speedway/lanidng%20image-2.png";

const aboutSections = [
  {
    title: "Our Mission",
    text: "At Data Speedway, we pave the way to digital transformation by simplifying IT for businesses. Our mission is to deliver streamlined, affordable, and seamless technology solutions that empower you to thrive in the digital age—without the complexity.",
    image: "https://storage.googleapis.com/app_darkendimg/assets/klandemo1.png",
  },
  {
    title: "Who We Are",
    text: "With over 25 years in IT deployment, we're trusted experts in digital transformation. Our passion for simplicity and innovation drives seamless, robust technology solutions for your business.",
    image: "https://storage.googleapis.com/app_darkendimg/assets/klandemo1.png",
  },
  {
    title: "What We Do",
    text: "At Data Speedway, we make digital transformation straightforward and accessible. We break down technical barriers so your business can innovate effortlessly.",
    image: "https://storage.googleapis.com/app_darkendimg/assets/klandemo1.png",
  },
];

const blogPosts = [
  {
    title: "The Future of Coffee & Blockchain",
    description: "How blockchain technology is changing the coffee industry.",
    image: "https://storage.googleapis.com/app_darkendimg/assets/klandemo1.png",
  },
  {
    title: "How DigiBeans Are Transforming Loyalty",
    description: "A new way to engage customers through digital coffee rewards.",
    image: "https://storage.googleapis.com/app_darkendimg/assets/klandemo1.png",
  },
  {
    title: "Direct Trade vs Fair Trade",
    description: "Understanding the differences and what they mean for farmers.",
    image: "https://storage.googleapis.com/app_darkendimg/assets/klandemo1.png",
  },
];

const HeroSection = () => (
  <Box
    sx={{
      position: "relative",
     
      height: { xs: "300px", sm: "400px", md: "500px" },
      backgroundImage: `url(${guy})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      textAlign: "center",
      padding: { xs: 2, sm: 5 },
    }}
  >
    <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
      <Typography variant="h2" fontWeight="bold">
        About Data Speedway
      </Typography>
      <Typography variant="h3" mt={2} sx={{ color: '#FACD00' }}>
        Paving your path to digital transformation.
      </Typography>
    </motion.div>
  </Box>
);

const AboutSection = ({ title, text, image, index }) => {
  const isEven = index % 2 === 0;
  return (
    <motion.div
      initial={{ opacity: 0, x: isEven ? -100 : 100 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <Container sx={{ py: 6 }}>
        <Grid container spacing={4} alignItems="center">
          {isEven ? (
            <>
              <Grid item xs={12} md={6}>
                <CardMedia component="img" height="300" image={image} sx={{ borderRadius: 2 }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h4" fontWeight="bold">{title}</Typography>
                  <Typography variant="body1" mt={2}>{text}</Typography>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h4" fontWeight="bold">{title}</Typography>
                  <Typography variant="body1" mt={2}>{text}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardMedia component="img" height="300" image={image} sx={{ borderRadius: 2 }} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </motion.div>
  );
};

const BlogGrid = () => (
  <Container sx={{ py: 6 }}>
    <Typography variant="h4" fontWeight="bold" textAlign="center" mb={4}>
      Latest News & Updates
    </Typography>
    <Grid container spacing={4} justifyContent="center">
      {blogPosts.map((post, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
            <CardMedia component="img" height="200" image={post.image} alt={post.title} />
            <CardContent>
              <Typography variant="h6" fontWeight="bold">{post.title}</Typography>
              <Typography variant="body2" mt={1} color="textSecondary">
                {post.description}
              </Typography>
              <Button size="small" sx={{ mt: 2 }} color="primary">
                Read More
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
);

const AboutPage = () => {
  return (
    <>
      <HeroSection />
      {aboutSections.map((section, index) => (
        <AboutSection key={index} index={index} {...section} />
      ))}
      <BlogGrid />
    </>
  );
};

export default AboutPage;
