import React from "react";
import { Container, Typography, Box, Card, Grid, Button } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const categories = [
    {
      category: "Koffieland Ecosystem Benefits",
      features: [
        {
          title: "Empowering Producers",
          description: "As a Koffielandeer farmer, enhance the value of your product and engage in the value-added side of the industry with revolutionary technology. Communicate with consumers in real-time, provide updates on the process, and offer Koffielandiërs a seed-to-cup experience. Increased farmgate value",
          footer: "Unite Your Cup with Koffieland",
          imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
        },
        {
            title: "Engaging for Drinkers",
            description: "An immersive and interactive coffee experience for drinkers. Enjoy personalized recommendations, interactive features, and a vibrant community of coffee enthusiasts. Whether you enjoy a plain ole cup or are a coffee connoisseur, we are  Koffielandiërs.",
            footer: "Unite Your Cup with Koffieland",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Boosted Sales for Shops",
            description: "Koffieland participating shops can allow Koffielandiërs to redeem drinks with DigiBeans, increasing engagement and profitability by reducing reliance on big koffie. Use Beans to interact with the Koffie ecosystem. Interact with users and businesses, and be among the first to join Koffieland.",
            footer: "Unite Your Cup with Koffieland",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          
          {
            title: "Rewards for Baristas",
            description: "Baristas are the unsung heroes of the industry, the mouthpiece, and the frontline. Reward baristas with DigiTips for their skills and dedication through our unique reward system. Earn recognition and highlight their vital role in creating an exceptional coffee experience.",
            footer: "Unite Your Cup with Koffieland",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Roasters Delight",
            description: "Become a Koffielandeer Roastmaster and expand your reach. Reduce overhead and create greater value while spreading your unique footprint. Engage with our community, enhance your brand, and take part in revolutionizing the coffee industry.",
            footer: "Unite Your Cup with Koffieland",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
        // Add more features as needed
      ]
    },
    {
      category: "BEC",
      features: [
        {
          title: "Bean Exchange Commission",
          description: " Overseeing and ensuring the GOOD and transparent movement of beans within the Koffie revolution. Each bean is securely represented as a blockchain token, guaranteeing authenticity and traceability.",
          footer: "Discover",
          imgSrc: "https://storage.googleapis.com/app_darkendimg/assets/phonemock.png"
        },
        {
            title: "Mission",
            description: "Our mission is to disrupt the koffie industry by partnering with coffee-producing countries. We value every coffee cherry at one pennyat farm gate, enhancing the experience and value for both producers and consumers, and enriching the entire coffee ecosystem.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
        {
            title: "Good Trade™ Smart Contracts",
            description: "Ensure transparency and goodness in every transaction from farm to cup with our Proof of Concept smart contract deployed on the XDC testnet. This contract monitors and manages lots based on 4K beans per pound.",
            footer: "Discover",
            imgSrc: "https://storage.googleapis.com/app_darkendimg/assets/phonemock.png"
          },
          {
            title: "DigiBeans for social interactions",
            description: "Use DigiBeans to post, like, comment, create and advertise your products, increasing visibility within the community and driving engagement to grow your business. Stimulate Koffieland's economy, add value to the DigiBean.",
            footer: "Discover",
            imgSrc: "https://storage.googleapis.com/app_darkendimg/assets/phonemock.png"
          },
          {
            title: "Traceability",
            description: "For consumers, this contract guarantees that the coffee they enjoy is sourced ethically and transparently. By scanning a code, they can trace their coffee's journey from the farm to their cup, reinforcing trust and encouraging responsible consumption.",
            footer: "Discover",
            imgSrc: "https://storage.googleapis.com/app_darkendimg/assets/phonemock.png"
          },
          
          
        // Add more features as needed
      ]
    },
    {
      category: "AI-Powered Features",
      features: [
        {
          title: "Smart Storefront",
          description: "Leverage the power of our AI Salesbot to effortlessly manage your sales. Simply add your items and let our expertly engineered AI handle the rest, driving sales and maximizing your revenue.",
          footer: "Explore",
          imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
        },
        {
            title: "AI Koffie Connoisseur",
            description: " Sip smarter! Get coffee tips and advice perfectly brewed to match your taste. Discover a whole new world of coffee, one personalized suggestion at a time.",
            footer: "Explore",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Generated Ads",
            description: "Let our Ad Bot generate your ads effortlessly. Use your DigiBeans to boost ad views and watch your sales soar! Engage with more customers and maximize your reach.",
            footer: "Explore",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Empowerment",
            description: "Empower people without access to new AI technology by building tailored AI services for various industries, enabling equitable technological advancement.",
            footer: "Explore",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
        
        // Add more features as needed
      ]
    },
    {
      category: "Journey",
      features: [
        {
          title: "Company",
          description: "Founded in 2012 as Good Kupa Koffie, we discovered the hardships faced by koffie farmers. Dedicated to disrupting unfair trade practices, we’ve been on a mission to make a change. This mission has evolved into an unexpected journey, leading us to create Koffieland and transform the coffee industry.",
          footer: "Join Us",
          imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
        },
        
        {
            title: "Where We're Coming From",
            description: "Rediscovering our early childhood koffie roots, we learned to grow, process, and roast coffee, ultimately opening a coffee shop and roastery. Our journey started with a passion for coffee and a commitment to fairness.",
            footer: "Join Us",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Where We're Going",
            description: "Leveraging my extensive IT experience and corporate knowledge, I created Koffieland. Our goal is to transform the coffee industry with innovative technology and transparent practices.",
            footer: "Join Us",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "The Journey Continues",
            description: "Embark on this exciting journey with us as we continue to carry out our mission. Become a part of Koffieland and help transform the coffee industry for the better. Together, we can make a meaningful impact from farm to your cup.",
            footer: "Explore",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          
          
        // Add more features as needed
      ]
    },
    {
      category: "KaaF™ - Koffie as a Function",
      features: [
        {
          title: "Koffie Function",
          description: "Wherever it's introduced, it has spelled innovation. It has been the world's most radical drink in that its function has always been to make people think. And when people begin to think, they become agents of change.",
          footer: "Discover",
          imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
        },
        {
            title: "Economic Function",
            description: "Using DigiBeans to increase the value of the koffie bean and put that value back in the hands of farmers with our tech. Reimagining exchange for value, empowering all stakeholders.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Social Function",
            description: "Uniting Koffielanders to transform the socio-economic landscape of the koffie industry, igniting innovation across sectors. Use your DigiBeans to like, share, and comment—spreading the word and uniting cups to challenge big coffee.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Political Function",
            description: "Partnering with koffie-growing countries, fostering economic growth, and empowering stakeholders by adding value and advocating for good trade practices.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
          {
            title: "Execute",
            description: "Continue the disruptive and innovative journey of koffie consumption with Koffieland. Empower change and drive the revolution in every cup.",
            footer: "Discover",
            imgSrc: 'https://storage.googleapis.com/app_darkendimg/profiles/654fb57d00514408a095c111/koffiecandy.png'
          },
        // Add more features as needed
      ]
    },
    
  ];

const CategoryContainer = styled(Box)({
    marginBottom: "40px",
    });
const CategoryTitle = styled(Typography)({
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "20px",
    });
const FeatureCard = styled(Card)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    textAlign: "center",
    });
const FeatureImage = styled("img")({
    width: "100px",
    height: "100px",
    marginBottom: "10px",
    });
const FeatureTitle = styled(Typography)({
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "10px",
    });
const FeatureDescription = styled(Typography)({
    fontSize: "1rem",
    marginBottom: "10px",
    });

const ExploreLink = styled(Link)({
    textDecoration: "none",
    color: "blue",
    "&:hover": {
    textDecoration: "underline",
    },
});
const Ecosystem = () => {
    return (
        <Container>
            <Box
        sx={{
          position: "relative",
          height: { xs: "400px", sm: "500px", md: "600px" }, // Responsive height
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end", // Align text to the right
          paddingRight: { xs: 2, sm: 5, md: 10 }, // Adjust padding for right-aligned text
          borderRadius: 2,
          overflow: "hidden",
          mt: 1
        }}
      >
        {/* Background Image Layer (Constrained to Container) */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/data-speedway/ecommerce.jpg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "#00bfff", // Fallback color
            filter: "brightness(0.9)", // Darkens for readability
            zIndex: -1
          }}
        />

        {/* Animated Text Box */}
        <motion.div
          initial={{ opacity: 0, x: 50 }} // Starts off-screen to the right
          animate={{ opacity: 1, x: 0 }} // Moves into position
          transition={{ duration: 1, ease: "easeOut" }}
          style={{
            maxWidth: "500px",
            textAlign: "right",
            background: "rgba(255,255,255,0.4)",
            padding: "20px",
            borderRadius: "10px"
          }}
        >
          <Typography variant="h3" fontWeight="bold" color="black">
            Custom Ecommerce Solution
          </Typography>
          <Typography variant="h6" color="textSecondary" mt={2} sx={{textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)" }}>
            Elevating coffee shop engagement through digital rewards.
          </Typography>

          {/* Buttons */}
          <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="contained" size="large" sx={{ backgroundColor: '#000', color: '#FACD00' }}>
              Get Started
            </Button>
            <Button variant="outlined"  size="large" sx={{ borderColor: '#000', color: '#FACD00' }}>
              Learn More
            </Button>
          </Box>
        </motion.div>
      </Box>
        {categories.map((category, index) => (
            <CategoryContainer key={index}>
            <CategoryTitle variant="h4">{category.category}</CategoryTitle>
            <Grid container spacing={4}>
                {category.features.map((feature, featureIndex) => (
                <Grid item xs={12} sm={6} md={4} key={featureIndex}>
                    <FeatureCard>
                    <FeatureImage src={feature.imgSrc} alt={feature.title} />
                    <FeatureTitle variant="h5">{feature.title}</FeatureTitle>
                    <FeatureDescription variant="body1">
                        {feature.description}
                    </FeatureDescription>
                    <ExploreLink to="/">{feature.footer}</ExploreLink>
                    </FeatureCard>
                </Grid>
                ))}
            </Grid>
            </CategoryContainer>
        ))}
        </Container>
    );
};

export default Ecosystem;