import React from "react";
import { Box, Container, Typography, Button, Grid, Card, CardMedia } from "@mui/material";
import { motion } from "framer-motion";

const users = [
  {
    type: "Drinker",
    description: "Enjoy coffee while earning DigiBeans and supporting ethical sourcing.",
    image: "https://source.unsplash.com/600x400/?coffee,drink",
    bg: "#f5f5f5",
  },
  {
    type: "Barista",
    description: "Showcase your craft, connect with drinkers, and get recognized.",
    image: "https://source.unsplash.com/600x400/?barista,coffee",
    bg: "#ffe5b4",
  },
  {
    type: "Farmer",
    description: "Sell directly to roasters and gain visibility in the digital economy.",
    image: "https://source.unsplash.com/600x400/?coffee,farm",
    bg: "#d4edda",
  },
  {
    type: "Coffee Shop",
    description: "Boost loyalty by offering digital rewards and personalized experiences.",
    image: "https://source.unsplash.com/600x400/?coffeeshop,cafe",
    bg: "#f8d7da",
  },
  {
    type: "Roaster",
    description: "Expand your reach, engage with consumers, and offer exclusive blends.",
    image: "https://source.unsplash.com/600x400/?coffee,roasting",
    bg: "#d1ecf1",
  },
];

const HeroSection = () => (
  <Box
    sx={{
      position: "relative",
      
      height: { xs: "400px", sm: "500px", md: "600px" },
      backgroundImage: `url(https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png)`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      textAlign: "center",
      padding: { xs: 2, sm: 5 },
    }}
  >
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <Typography variant="h2" fontWeight="bold">
        Welcome to Koffieland SaaS
      </Typography>
      <Typography variant="h5" mt={2}>
        A new way to connect coffee drinkers, baristas, farmers, shops, and roasters.
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="primary" sx={{ mr: 2 }}>
          Get Started
        </Button>
        <Button variant="outlined" color="secondary">
          Learn More
        </Button>
      </Box>
    </motion.div>
  </Box>
);

const UserSection = ({ type, description, image, bg, index }) => {
  const isEven = index % 2 === 0;
  return (
    <motion.div
      initial={{ opacity: 0, x: isEven ? -100 : 100 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <Box sx={{ backgroundColor: bg, py: 6 }}>
        <Container>
          <Grid container spacing={4} alignItems="center">
            {isEven ? (
              <>
                <Grid item xs={12} md={6}>
                  <CardMedia component="img" height="300" image={image} sx={{ borderRadius: 2 }} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ boxShadow: 3, borderRadius: 3, p: 3 }}>
                    <Typography variant="h4" fontWeight="bold">
                      {type}
                    </Typography>
                    <Typography variant="body1" mt={2}>
                      {description}
                    </Typography>
                  </Card>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Card sx={{ boxShadow: 3, borderRadius: 3, p: 3 }}>
                    <Typography variant="h4" fontWeight="bold">
                      {type}
                    </Typography>
                    <Typography variant="body1" mt={2}>
                      {description}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardMedia component="img" height="300" image={image} sx={{ borderRadius: 2 }} />
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Box>
    </motion.div>
  );
};

const UserPage = () => {
  return (
    <>
      <HeroSection />
      {users.map((user, index) => (
        <UserSection key={index} index={index} {...user} />
      ))}
    </>
  );
};

export default UserPage;
