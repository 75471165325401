import { useEffect, useRef } from "react";
import { Card, Box } from "@mui/material";

const VideoComponent = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Give the browser a small delay before trying to autoplay
      setTimeout(() => {
        video.play().catch((error) => {
          console.log("Autoplay prevented:", error);
        });
      }, 100);
    }
  }, []);

  return (
    <Card
      sx={{
        position: "relative",
        width: "100%",
        // You can use a fixed height or an aspect ratio
        // Example below uses 16:9 aspect ratio
        paddingTop: "56.25%", 
        overflow: "hidden",
        boxShadow: 4,
        borderRadius: 3,
        "&:hover": {
          transform: "scale(1.02)",
          transition: "0.3s",
        },
      }}
    >
      {/* The video is absolutely positioned to fill the Card */}
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source
          src="https://storage.googleapis.com/app_darkendimg/data-speedway/introduction.64ab6a6b.mp4"
          type="video/mp4"
        />
        {/* Fallback Image */}
       
      </video>

      {/* Optional Overlay Content (if needed) */}
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          color: "#fff",
          p: 2,
        }}
      >
        {/* Your overlay text or elements go here */}
      </Box>
    </Card>
  );
};

export default VideoComponent;
