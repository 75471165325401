import React from "react";
import { Box, Container, Typography, Button, Grid, Card,  CardMedia } from "@mui/material";
import { motion } from "framer-motion";

const users = [
  {
    type: "Networks",
    description: "Enjoy coffee while earning DigiBeans and supporting ethical sourcing.",
    image: "https://storage.googleapis.com/app_darkendimg/data-speedway/network-all-in-one.mp4",
    bg: "#121212",
  },
  {
    type: "Security Compliance",
    description: "Showcase your craft, connect with drinkers, and get recognized.",
    image: "https://storage.googleapis.com/app_darkendimg/data-speedway/network-all-in-one.mp4",
    bg: "#ebecf0",
  },
  {
    type: "Security Cameras",
    description: "Sell directly to roasters and gain visibility in the digital economy.",
    image: "https://storage.googleapis.com/app_darkendimg/data-speedway/introduction.64ab6a6b.mp4",
    bg: "#121212",
  },
  {
    type: "EV Charging Stations",
    description: "Boost loyalty by offering digital rewards and personalized experiences.",
    image: "https://storage.googleapis.com/app_darkendimg/data-speedway/ev-charge.mp4",
    bg: "#fff",
  },
  {
    type: "Roaster",
    description: "Expand your reach, engage with consumers, and offer exclusive blends.",
    image: "https://source.unsplash.com/600x400/?coffee,roasting",
    bg: "#121212",
  },
];

const HeroSection = () => (
  <Box
    sx={{
      position: "relative",
      
      height: { xs: "400px", sm: "500px", md: "600px" },
      overflow: "hidden",
    }}
  >
    {/* Video Background */}
    <video
      autoPlay
      loop
      muted
      playsInline
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
      poster="https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png" // fallback image
    >
      <source
        src="https://storage.googleapis.com/app_darkendimg/data-speedway/networking-2.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>

    {/* Content Overlay */}
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
       
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        textAlign: "center",
        p: { xs: 2, sm: 5 },
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Typography variant="h2" fontWeight="bold" sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
          Network Solutions
        </Typography>
        <Typography variant="h4" mt={2} sx={{ color: '#FACD00', textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
          Reliable and fast network solutions for your business.
        </Typography>
        <Box mt={4}>
          <Button variant="contained"  sx={{ mr: 2, backgroundColor: '#facd00', color: '#000' }}>
            Get Started
          </Button>
          <Button variant="outlined"  sx={{ borderColor: '#000', color: '#FACD00' }}>
            Learn More
          </Button>
        </Box>
      </motion.div>
    </Box>
  </Box>
);
const UserSection = ({ type, description, image, bg, index }) => {
  const isEven = index % 2 === 0;

  // Check if the URL points to a video
  const isVideo = image?.toLowerCase().endsWith('.mp4');

  // Create a renderMedia function to simplify the JSX below
  const renderMedia = () => {
    if (isVideo) {
      return (
        <CardMedia
          component="video"
          height="300"
          src={image}
          sx={{ borderRadius: 5 }}
          autoPlay
          loop
          muted
          controls={false}
        />
      );
    }
    return (
      <CardMedia
        component="img"
        height="300"
        image={image}
        sx={{ borderRadius: 2 }}
      />
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: isEven ? -100 : 100 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <Box sx={{ backgroundColor: bg, py: 6 }}>
        <Container>
          <Grid container spacing={4} alignItems="center">
            {isEven ? (
              <>
                <Grid item xs={12} md={6}>
                  {renderMedia()}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ boxShadow: 3, borderRadius: 3, p: 3 }}>
                    <Typography variant="h4" fontWeight="bold">
                      {type}
                    </Typography>
                    <Typography variant="body1" mt={2}>
                      {description}
                    </Typography>
                  </Card>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Card sx={{ boxShadow: 3, borderRadius: 3, p: 3 }}>
                    <Typography variant="h4" fontWeight="bold">
                      {type}
                    </Typography>
                    <Typography variant="body1" mt={2}>
                      {description}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderMedia()}
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Box>
    </motion.div>
  );
};

const ShopPage = () => {
  return (
    <>
      <HeroSection />
      {users.map((user, index) => (
        <UserSection key={index} index={index} {...user} />
      ))}
    </>
  );
};

export default ShopPage;
