import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './home';
import PortalFooter from "./pfooter";
import PrivacyPolicy from "./privacy";
import TermsOfService from "./terms";
import SaaSPage from "./infopage";
import MenuComponent from "./menu";
import FeaturesPage from "./features";
import Koffieland from "./koffieland";
import Ecosystem from "./ecosystem";
import Demo from "./demo";
import UserPage from "./usertype";
import ShopPage from "./koffieshop";
import RoasterPage from "./roaster";
import BaristaPage from "./barista";
import FarmerPage from "./farmer";
import AboutPage from "./bout";
import BookDemoForm from "./bookit";


const App = () => {
  return (
    <BrowserRouter>
    <MenuComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/info" element={<SaaSPage />} />
        <Route path="/features" element={<FeaturesPage />} />
        <Route path="/koffieland" element={<Koffieland />} />
        <Route path="/ecosystem" element={<Ecosystem />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="userpage" element={<UserPage/>} />
        <Route path="shoppage" element={<ShopPage/>} />
        <Route path="/roasterpage" element={<RoasterPage/>} />
        <Route path="/baristapage" element={<BaristaPage/>} />
        <Route path="/farmerpage" element={<FarmerPage />} />
        <Route path="/about/company" element={<AboutPage />} />  
        <Route path="/bookit" element={<BookDemoForm />} />
              {/* Add more routes as needed */}
      </Routes>
      <PortalFooter />
    </BrowserRouter>
  );
};

export default App;
