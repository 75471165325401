import React, { } from "react";
import {
  Box,
  Grid,
  Typography,
  Card, 
  CardContent,
  Button,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import VideoComponent from "./vidcomp";
import { Link } from "react-router-dom";


const guy = "https://storage.googleapis.com/app_darkendimg/data-speedway/lanidng%20image-2.png";

// Example Product Data
const products = [
  {
    id: 1,
    title: "Spotlight Item",
    price: "$28",
    img: "https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png",
    spotlight: true,
  },
  {
    id: 5,
    title: "Software & Cloud",
    price: "$25",
    img: "https://storage.googleapis.com/app_darkendimg/data-speedway/cloud-1.png",
  },
  {
    id: 2,
    title: "Network & Telecom",
    price: "$26",
    img: "https://storage.googleapis.com/app_darkendimg/data-speedway/ICX8200.png",
  },
  
  {
    id: 4,
    title: "Wireless Solutions",
    price: "$20",
    img: "https://storage.googleapis.com/app_darkendimg/data-speedway/dswap.png",
  },
  {
    id: 3,
    title: "Data Lines",
    price: "$28",
    img: "https://storage.googleapis.com/app_darkendimg/data-speedway/patch.png",
  },
  
];



// Spotlight Product
const HeadCard = () => (
  <Card
    sx={{
      boxShadow: 0,
      borderRadius: 3,
      padding: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "left",
      mt: 2,
      mb: 2,
    }}
  >
    <Box sx={{ overflow: "hidden", mb: 0, mt: 4 }}>
      <Typography
        variant="h1"
        gutterBottom
        sx={{
          fontWeight: 600,
          fontSize: {
            xs: "2.0rem", // Font size for extra small screens (mobile)
            sm: "3.0rem", // Font size for small screens
            md: "3.0rem", // Font size for medium and larger screens
          },
        }}
      >
        Fast track your digital transformation
        
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1rem", // Adjusted font size for mobile
            sm: "1.25rem", // Small screens
            md: "1.5rem", // Medium and larger screens
          },
        }}
      >
        The DATA-SPEEDWAY
      </Typography>

      <Box sx={{ display: "flex",  gap: 2, mt: 3 , boxShadow: 0 }}>
        
        <Button variant="contained"  sx={{ boxShadow: 0 , borderRadius: 50, px: 4, backgroundColor: "#000", fontWeight: "bold", fontSize: "1.2rem", color: "#FACD00" }}
        LinkComponent={Link} to="/demo"
        >
          View Demo
        </Button>
       
        <Box   sx={{ display: "flex", alignItems: "center" }}>
        <IconButton LinkComponent={Link} to="/bookit" sx={{borderRadius: 50, p: 1, border: 'solid 2px'}}>
            <ArrowForwardIosIcon  fontSize="small" sx={{color: "#FACD00"}}/>
            </IconButton>
            <Typography variant="body1" gutterBottom sx={{mt: 1, padding: 1, fontWeight: '500', color: 'black', textShadow: '2px'}}>
              Lets' Talk
            </Typography>
            </Box>
      </Box>
    </Box>
  </Card>
);



const MotionBox = motion(Box);
const ProductCard1 = ({ product }) => (
  <MotionBox
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
    sx={{
      width: "100%",
    }}
  >
    <Card
      sx={{
        position: "relative",
        width: "100%",
        // The classic 16:9 ratio: 9/16 = 56.25%
        pt: "56.25%",        // padding-top sets aspect ratio
        overflow: "hidden",
        boxShadow: 4,
        mt:2,
        borderRadius: 3,
        "&:hover": {
          transform: "scale(1.02)",
          transition: "0.3s",
        },
      }}
    >
      {/* Background image is absolutely positioned to fill this "16:9" space */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${product.image})`,
          backgroundSize: "cover",    // "cover" crops edges; use "contain" to show full image but possibly with empty space
          backgroundPosition: "center",
        }}
      />

      {/* Overlay Content */}
      <Box
        sx={{
          position: "absolute",
          zIndex: 1,
          bottom: 0,
          left: 0,
          width: "100%",
          color: "#fff",
          p: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          {product.title}
        </Typography>
        <Typography variant="body1">{product.description}</Typography>
      </Box>
    </Card>
  </MotionBox>
);

const productData = [
  {
    id: 1,
    title: "Product One",
    image: "https://storage.googleapis.com/app_darkendimg/assets/koffieroast.png",
    description: "Description for Product One",
  },
  {
    id: 2,
    title: "Product Two",
    image: "https://storage.googleapis.com/app_darkendimg/assets/justcoffee.png",
    description: "Description for Product Two",
  },
  {
    id: 3,
    title: "Product Three",
    image: "https://storage.googleapis.com/app_darkendimg/assets/jebena.png",
    description: "Description for Product Three",
  },
];


const HeroCard = () => (
  <Card
    sx={{
      boxShadow: 0,
      borderRadius: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start", // Align items to the left
      textAlign: "left",
      width: "100%",
      boxSizing: "border-box",
      overflow: "hidden",
      backgroundColor: "transparent", // Ensure transparency if needed
      mb: 1, // Margin bottom for spacing
    }}
  >
    <Box
      sx={{
        backgroundImage: `url(${guy})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: { xs: "200px", sm: "300px", md: "600px" }, // Responsive heights
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start", // Align content to the left
        padding: { xs: 2, sm: 3, md: 4 }, // Responsive padding
        boxSizing: "border-box",
        position: "relative",
        borderRadius: 2, // Match Card's borderRadius
        backgroundColor: "rgba(0, 0, 0, 0.4)", // Optional: semi-transparent overlay for text readability
        aspectRatio: "16/9", // Aspect ratio for responsive images''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
      }}
    >
      <Typography
        variant="h1"
        gutterBottom
        sx={{
          fontWeight: 400,
          fontSize: {
            xs: "2rem", // Smaller font size for mobile
            sm: "2.5rem",
            md: "3rem",
          },
          color: "white", // Ensure text is visible against the background
          textAlign: "left",
          width: "100%", // Prevent text overflow
          mb: 1, // Margin bottom for spacing
        }}
      >
        Experience the Digi Bean
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontSize: {
            xs: "0.9rem", // Adjusted font size for mobile
            sm: "1rem",
            md: "1.2rem",
          },
          color: "white",
          textAlign: "left",
          width: "100%",
          mb: 3, // Margin bottom to separate from buttons
        }}
      >
        Where every bean is a story
      </Typography>

      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="contained"
         
          sx={{
            borderRadius: 50,
            px: { xs: 3, sm: 4 },
            py: { xs: 1, sm: 1.5 },
            fontSize: { xs: "0.8rem", sm: "1rem" },
            background: "#FACD00",
          }}
        >
          Learn More
        </Button>
        <Button
          variant="outlined"
          
          sx={{
            borderRadius: 50,
            px: { xs: 3, sm: 4 },
            py: { xs: 1, sm: 1.5 },
            fontSize: { xs: "0.8rem", sm: "1rem" },
            color: "#fff",
            border: "1px solid #FACD00",
          }}
        >
          Do More
        </Button>
      </Box>
    </Box>
  </Card>
);

const HeroCard2 = () => (
    <Card
      sx={{
        boxShadow: 0,
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", // Align items to the left
        textAlign: "left",
        width: "100%",
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: "transparent", // Ensure transparency if needed
        mb: 1, // Margin bottom for spacing
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${'https://storage.googleapis.com/app_darkendimg/data-speedway/ai-agent.png'})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: { xs: "200px", sm: "300px", md: "500px" }, // Responsive heights
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start", // Align content to the left
          padding: { xs: 2, sm: 3, md: 4 }, // Responsive padding
          boxSizing: "border-box",
          position: "relative",
          borderRadius: 2, // Match Card's borderRadius
          backgroundColor: "rgba(0, 0, 0, 0.4)", // Optional: semi-transparent overlay for text readability
        }}
      >
        {/* Animated Heading */}
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
          style={{ width: "100%" }}
        >
        <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: 500,
              fontSize: {
                xs: "1.5rem", // Smaller for mobile
                sm: "2rem",
                md: "3rem",
              },
              color: "white",
              textAlign: "right",
              maxWidth: "90%", // Prevent text overflow
              mx: "auto",
              mb: 1,
              lineHeight: 1.2,
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            AI agents increase productivity
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.8rem", // Smaller for mobile
                sm: "1rem",
                md: "1.2rem",
              },
              color: "white",
              textAlign: "right",
              maxWidth: "90%",
              mx: "auto",
              mb: 3,
            }}
          >
            Get ahead of the competition
          </Typography>
        </motion.div>
       <Box
            sx={{
              display: "flex",
             
              gap: 2,
              justifyContent: "flex-end",
              width: "100%",
              alignItems: { xs: "flex-end", sm: "center" }, // Keep buttons aligned right
            }}
          >
            <Button
              variant="contained"
              
              sx={{
                borderRadius: 50,
                px: { xs: 1, sm: 3, md: 4 },
                py: { xs: 0.8, sm: 1, md: 1.5 },
                fontSize: { xs: "0.5rem", sm: "0.9rem", md: "1rem" },
                width: { xs: "25%", sm: "auto" }, // Full width on mobile
                background: "#000",
              }}
            >
              Learn More
            </Button>
            <Button
              variant="outlined"
              color="white"
              sx={{
                borderRadius: 50,
                px: { xs: 1, sm: 3, md: 4 },
                py: { xs: 0.8, sm: 1, md: 1.5 },
                fontSize: { xs: "0.5rem", sm: "0.9rem", md: "1rem" },
                width: { xs: "25%", sm: "auto" }, // Full width on mobile
                color: "#fff",
              }}
            >
              Get Started
            </Button>
          </Box>

      </Box>
    </Card>
  );
  
const Home = () => {
   
  return (
    <Box
      sx={{
        minHeight: "100vh",
        bgcolor: "background.default",
        overflowX: "hidden",
        // Updated for more granular responsiveness:
        px: { xs: 1, sm: 2, md: 4, lg: 6 },
      }}
    >
      {/* Header */}
      

      {/* Main Content */}
      <Box
        sx={{
          minHeight: "100vh",
          // Updated padding for responsiveness:
          p: { xs: 1, sm: 2, md: 3 },
          pt: 3,
        }}
      >
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={4}>
            <HeadCard />
          </Grid>
          <Grid item xs={12} sm={8}>
            <VideoComponent />
          </Grid>
        </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                <HeroCard2/>
              
              </Grid>
              </Grid>
        {/* Product Grid */}
        <Grid container spacing={3}> {/* Increased spacing to 3 */}
  {/* Header Text */}
  <Grid item xs={12}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        mt: 1,
      }}
    >
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        sx={{
          fontSize: {
            xs: "1.5rem", // Font size for mobile screens
            sm: "2rem",   // Font size for small screens
            md: "2.5rem", // Font size for medium screens
            lg: "3rem",   // Font size for large screens
          },
        }}
      >
        Data speedway Eco-System
      </Typography>
    </Box>
  </Grid>

  {/* Product Cards */}
  {products.slice(1, 5).map((product) => (
    <Grid key={product.id} item xs={6} sm={6} md={3} gap={2}>
      <Box>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Card
            sx={{
              boxShadow: 4,
              borderRadius: 3,
              position: "relative",
              backgroundColor: "#ebecf0",
              paddingTop: "50px", // Adjust for the image
              overflow: "visible",
              margin: "8px", // Adds space around the card
              mt: 2, // Adds space between the cards
            }}
          >
            {/* Product Image */}
            <Box
              component="img"
              src={product.img}
              alt={product.title}
              sx={{
                width: { xs: "80px", sm: "100px", md: "120px" }, // Responsive image size
                height: { xs: "80px", sm: "100px", md: "120px" },
                objectFit: "contain",
                position: "absolute",
                top: "-40px",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />

            {/* Card Content */}
            <CardContent sx={{ textAlign: "center" , mt: 1}}>
              <Typography variant="subtitle1" fontWeight="bold">
                {product.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {product.price}
              </Typography>
              <Button
                variant="contained"
                
                sx={{ marginTop: "10px", borderRadius: 3 , background: "#000", color: '#FACD00' }}
              >
                Learn More
              </Button>
            </CardContent>
          </Card>
        </motion.div>
      </Box>
    </Grid>
  ))}
</Grid>
<Grid container spacing={2}>
                <Grid item xs={12} >
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center', mt: 1}}>
                    <Typography variant="subtitle1" fontWeight="bold" sx={{fontSize: {xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem'}}}>
                        The Koffiehand Eco-System
                    </Typography>
                    </Box>
                <HeroCard/>
              
              </Grid>
              </Grid>
              <Grid container spacing={2}>
  {productData.map((product) => (
    <Grid item xs={12} sm={6} md={4} key={product.id}>
      <ProductCard1 product={product} />
    </Grid>
  ))}
</Grid>

      </Box>
      
    </Box>
  );
};

export default Home;
