import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const BookDemoForm = () => {
  // State for each field (if you plan to use the contact form)
  const [companyName, setCompanyName] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [comments, setComments] = useState('');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Inject Calendly widget script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Handle form submission (if needed)
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!companyName || !fullName || !email) {
      alert('Please fill in all required fields.');
      return;
    }
    const formData = { companyName, fullName, email, phone, comments };
    console.log('Form submitted:', formData);
    // Clear the form
    setCompanyName('');
    setFullName('');
    setEmail('');
    setPhone('');
    setComments('');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        minHeight: '100vh',
        py: 4,
        px: 2,
        
      }}
    >
      {/* Header Section */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
          Let's Talk
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Let us show you how our solution can empower your business.
        </Typography>
        {/* Optional image */}
        <Box
          component="img"
          src="https://via.placeholder.com/300x100?text=Your+Brand+Logo"
          alt="Brand Logo"
          sx={{ mt: 2, maxWidth: '100%', height: 'auto' }}
        />
      </Box>

      {/* Main Content Section */}
      <Grid container spacing={4} justifyContent="center" alignItems="stretch">
        {/* Contact Form */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Contact Information
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                required
                label="Company Name"
                variant="outlined"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                required
                label="Full Name"
                variant="outlined"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                required
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Comments"
                variant="outlined"
                multiline
                rows={3}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button type="submit" variant="contained" sx={{backgroundColor: '#000', color: '#FACD00'}}>
                Submit
              </Button>
            </form>
          </Paper>
        </Grid>

        {/* Divider with "Or" */}
        {isSmallScreen ? (
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }}>Or</Divider>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Divider orientation="vertical" flexItem>
              Or
            </Divider>
          </Grid>
        )}

        {/* Calendly Widget */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ p: 3, height: isSmallScreen ? 'auto' : 700 }}>
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Typography variant="h6">
                Choose a time slot that works for you
              </Typography>
            </Box>
            <Box
              className="calendly-inline-widget"
              data-url="https://calendly.com/richard-hinds-yycn"
              sx={{ minWidth: '320px', height: isSmallScreen ? 500 : 600 }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookDemoForm;
