import React from 'react';
import { Container, Typography,} from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';


const BackgroundImage = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: -1,
    filter: 'blur(8px)',
    transform: 'scale(1.1)',
    transition: 'transform 0.3s ease-in-out',
    transformOrigin: 'center',
    '@media (max-width:600px)': {
        filter: 'blur(4px)',
    },
});

const Content = styled('div')({
    position: 'relative',
    zIndex: 1,
    padding: '20px',
    '@media (max-width:600px)': {
        padding: '10px',
    },
});


const Title = styled(Typography)({
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    '@media (max-width:600px)': {
        fontSize: '1.8rem',
    },
});


const Description = styled(Typography)({
    fontSize: '1.2rem',
    marginBottom: '20px',
    '@media (max-width:600px)': {
        fontSize: '1rem',
    },
});
const LinkStyled = styled(Link)({
    textDecoration: 'none',
    color: 'blue',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const Image = styled('img')({
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
});
const VideoContainer = styled('div')({
    position: 'relative',
    paddingTop: '56.25%',
    marginBottom: '20px',
});
const Video = styled('video')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});


const Koffieland = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   
    const [backgroundImage, setBackgroundImage] = useState('');

    const videoRef = useRef(null);

    const videoSources = useMemo(
        () => [
            'https://storage.googleapis.com/app_darkendimg/assets/pick1.mp4',
            'https://storage.googleapis.com/app_darkendimg/assets/pick2.mp4',
            'https://storage.googleapis.com/app_darkendimg/assets/pick3.mp4',
        ],
        []
    );

    const currentVideoSource = useMemo(() => {
        const index = Math.floor(Math.random() * videoSources.length);
        return videoSources[index];
    }, [videoSources]);

    useEffect(() => {
        if (isMobile) {
            setBackgroundImage('https://source.unsplash.com/random');
        } else {
            setBackgroundImage(
                'https://storage.googleapis.com/app_darkendimg/assets/saasbg.jpg'
            );
        }
    }, [isMobile]);
    const handleVideoError = useCallback(() => {
        console.error('Error loading video');
    }, []);
    return (
        <Container maxWidth="lg">
            <BackgroundImage style={{ backgroundImage: `url(${backgroundImage})` }} />
            <Content>
                <Title variant="h4" align="center">
                    Welcome to KoffieLand
                </Title>
                <Description variant="body1" align="center">
                    Discover the perfect blend of coffee and convenience with KoffieLand.
                    Explore our range of coffee options and enjoy a seamless shopping
                    experience. From farm to cup, we're here to make your coffee journey
                    exceptional.
                </Description>
                <LinkStyled to="/order">Order Now</LinkStyled>
                <VideoContainer>
                    <Video ref={videoRef} autoPlay loop muted playsInline onError={handleVideoError}>
                        <source src={currentVideoSource} type="video/mp4" />
                        Your browser does not support the video tag.
                    </Video>
                </VideoContainer>
                <Image src="https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png" alt="KoffieLand" />
            </Content>
           
        </Container>
    );
}

export default Koffieland;