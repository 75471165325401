import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Container,
  Button,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { motion } from "framer-motion";

const benefits = [
  {
    title: "Zero Disruption to Operations",
    points: [
      "Shops sell their beans through existing POS systems and collect 100% of the revenue.",
      "No extra setup or changes required for operation.",
    ],
    image: "https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png",
  },
  {
    title: "Enhanced Customer Experience",
    points: [
      "Customers earn DigiBeans with every coffee purchase, unlocking rewards and engagement in the Coffee Land app.",
    ],
    image: "https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png",
  },
  {
    title: "Increased Customer Loyalty",
    points: [
      "DigiBeans create a reason for customers to return and repurchase coffee.",
    ],
    image: "https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png",
  },
  {
    title: "A Unique Selling Point",
    points: [
      "Shops differentiate by offering “coffee with a digital twist,” adding value beyond just the product.",
    ],
    image: "https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png",
  },
  {
    title: "No Risk, High Reward",
    points: [
      "No upfront cost to participate—Coffee Land handles the DigiBean system entirely.",
    ],
    image: "https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png",
  },
];

const SaaSPage = () => {
  return (
    <Container maxWidth="lg">
     <Box
        sx={{
          position: "relative",
          height: { xs: "400px", sm: "500px", md: "600px" }, // Responsive height
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end", // Align text to the right
          paddingRight: { xs: 2, sm: 5, md: 10 }, // Adjust padding for right-aligned text
          borderRadius: 2,
          overflow: "hidden",
          mt:1
        }}
      >
        {/* Background Image Layer (Constrained to Container) */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/data-speedway/ai-agent.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "brightness(0.7)", // Darkens for readability
            zIndex: -1
          }}
        />

        {/* Animated Text Box */}
        <motion.div
          initial={{ opacity: 0, x: 50 }} // Starts off-screen to the right
          animate={{ opacity: 1, x: 0 }} // Moves into position
          transition={{ duration: 1, ease: "easeOut" }}
          style={{
            maxWidth: "500px",
            textAlign: "right",
            background: "rgba(255,255,255,0.8)",
            padding: "20px",
            borderRadius: "10px"
          }}
        >
          <Typography variant="h3" fontWeight="bold" sx={{ color: '#000' }}>
            Leverage AI to LLM Agents.
          </Typography>
          <Typography variant="h6" color="textSecondary" mt={2}>
            Elevating coffee shop engagement through digital rewards.
          </Typography>

          {/* Buttons */}
          <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="contained" color="primary" size="large"sx={{ backgroundColor: '#facd00', color: '#000' }}>
              Get Started
            </Button>
            <Button variant="outlined"  size="large" sx={{ borderColor: '#FACD00', color: '#000' }}>
              Learn More
            </Button>
          </Box>
        </motion.div>
      </Box>

      {/* Key Benefits Section */}
      <Typography variant="h4" align="center" fontWeight="bold" mt={5} mb={3}>
        Key Benefits for Coffee Shops
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {benefits.map((benefit, index) => (
          <Grid item xs={12} md={5} key={index}>
            <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
              <CardMedia
                component="img"
                height="200"
                image={benefit.image}
                alt={benefit.title}
              />
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  {benefit.title}
                </Typography>
                <List>
                  {benefit.points.map((point, i) => (
                    <ListItem key={i} disableGutters>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: '#077336'}} />
                      </ListItemIcon>
                      <ListItemText primary={point} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Revenue Model Section */}
      <Box textAlign="center" mt={8}>
        <Typography variant="h4" fontWeight="bold" mb={3}>
          Revenue Model for Coffee Land
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
            <CardMedia
              component="img"
              height="200"
              image="https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png"
              alt="Subscription"
            />
            <CardContent>
              <Typography variant="h6" fontWeight="bold">
                Subscription Fees for Shops
              </Typography>
              <Typography variant="body2">
                Coffee shops pay a small monthly fee for DigiBean tools, including:
              </Typography>
              <List>
                <ListItem disableGutters>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Branded DigiBean promotions." />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Customer analytics for engagement insights." />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
            <CardMedia
              component="img"
              height="200"
              image="https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png"
              alt="Premium Features"
            />
            <CardContent>
              <Typography variant="h6" fontWeight="bold">
                Premium Features
              </Typography>
              <List>
                <ListItem disableGutters>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Boosted Rewards: Offer 'Double DigiBeans' on special days." />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Custom Campaigns: Get featured in Coffee Land’s app promotions." />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
            <CardMedia
              component="img"
              height="200"
              image="https://storage.googleapis.com/app_darkendimg/assets/koffiehands.png"
              alt="Redemption Fees"
            />
            <CardContent>
              <Typography variant="h6" fontWeight="bold">
                Redemption Fees
              </Typography>
              <Typography variant="body2">
                Coffee Land earns a small fee when DigiBeans are redeemed for coffee at participating shops.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Pitching Section */}
      <Box textAlign="center" mt={8} mb={5}>
        <Typography variant="h4" fontWeight="bold" mb={3}>
          Pitching to Coffee Shops
        </Typography>
        <Typography variant="body1" color="textSecondary" maxWidth="md" mx="auto">
          A direct, value-driven approach is key. Emphasize no disruption, low risk, and high rewards.
        </Typography>
      </Box>

      {/* Footer Section */}
      <Box textAlign="center" my={5} py={3} sx={{ bgcolor: "#f5f5f5", borderRadius: 2 }}>
        <Typography variant="h6" color="primary">
          Get Started with Coffee Land Today!
        </Typography>
        <Typography variant="body2">
          Join now and enhance your coffee shop's customer experience.
        </Typography>
      </Box>
    </Container>
  );
};

export default SaaSPage;
