import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";


const menuData = [
  {
    title: "Products",
    items: [
      { name: "AI Agents", link: "/info" },
      { name: "Digital Assets", link: "/features" },
      { name: 'Ecommerce', link: '/ecosystem' },
      { name: "Enterprise Resource Management", link: "/web3" },
    ],
  },
  {
    title: "Services", 
    items: [
      { name: "Software & Cloud", link: "/userpage" },
      { name: "Network & Telecom", link: "/shoppage" },
        { name: "Wireless Solutions", link: "/roasterpage" },
        { name: "Data Lines", link: "/baristapage" },
        
    ],
  },
  {
    title: "About",
    items: [
      { name: "Company", link: "/about/company" },
      { name: "Team", link: "/about/team" },
    ],
  },
  {
    title: "Contact",
    items: [
      { name: "Let's Talk", link: "/bookit" },
      { name: "Support", link: "/contact/support" },
      
    ],
  },
];

const MenuComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMenuOpen = (event, title) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(title);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white", boxShadow: 1 }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Logo */}
        <Box display="flex" alignItems="center">
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              src="https://storage.googleapis.com/app_darkendimg/data-speedway/DP-logo-2.png"
              alt="logo"
              width="180px"
              height="40px"
              style={{ cursor: "pointer" }}
            />
          </Link>
        </Box>

        {/* Desktop Menu (Hidden on Mobile) */}
        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
          {menuData.map((menu, index) => (
            <Box key={index} sx={{ mx: 2 }}>
              <Button
                onClick={(e) => handleMenuOpen(e, menu.title)}
                endIcon={<ArrowDropDownIcon />}
                sx={{ color: "black", textTransform: "none",  }}
              >
                {menu.title}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={currentMenu === menu.title}
                onClose={handleMenuClose}
                PaperProps={{ sx: { mt: 1, border: "1px solid #ddd", borderRadius: 2 } }}
              >
                {menu.items.map((item, i) => (
                  <MenuItem
                    key={i}
                    component={Link}
                    to={item.link}
                    onClick={handleMenuClose}
                    sx={{ color: "#333" }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ))}
        </Box>

        {/* Profile Icon */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton>
            <PersonIcon fontSize="medium"  sx={{color: 'black'}}/>
          </IconButton>

          {/* Mobile Menu Icon (Hidden on Desktop) */}
          <IconButton
            sx={{ display: { xs: "flex", md: "none" }, ml: 2 }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon fontSize="large" sx={{color: 'black'}} />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Mobile Drawer Menu */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <Box sx={{ width: 250 }}>
          <List>
            {menuData.map((menu, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText primary={menu.title} sx={{ fontWeight: "bold" }} />
                </ListItem>
                {menu.items.map((item, i) => (
                  <ListItem button key={i} component={Link} to={item.link} onClick={handleDrawerToggle}>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default MenuComponent;
