import React from "react";
import { Container, Grid, Box, Typography, Card, CardContent, CardMedia,  } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


const features = [
    { 
      title: 'Digitized Koffie Beans', 
      description: 'Transform your coffee purchases into digital beans, fostering transparency and fairness in the coffee trade.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/kcoin.png' 
    },
    { 
      title: 'Smart Contracts', 
      description: 'Implement transparent and trustless smart contracts to ensure Good Trade™ and secure transactions.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/contricon.png' 
    },
    { 
      title: 'AI Salesbot', 
      description: 'Create personalized shopping carts, automate sales, and receive payments through Zelle and Cash App.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/boticon.png' 
    },
    { 
      title: 'AI Koffie Connoisseur', 
      description: 'Experience our groundbreaking AI Connoisseur for personalized coffee recommendations and enhanced experiences.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/aicon.png' 
    },
    { 
      title: 'Direct Chat with Producers', 
      description: 'Communicate directly with coffee producers to build better relationships and transparency.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/chaticon.png' 
    },
    { 
      title: 'Digi Beans for Social Interactions', 
      description: 'Use Digi Beans to post, like, and advertise your products, increasing visibility within the community.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' 
    },
    { 
      title: 'Posts from Your Favorite Shop', 
      description: 'Stay updated with the latest posts and offers from your favorite coffee shops.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' 
    },
    { 
      title: 'History of Koffie', 
      description: 'Dive into the rich history and culture of coffee through our comprehensive platform.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/assets/historyicon.png' 
    },
    { 
      title: 'Koffie EDU', 
      description: 'Access educational content about coffee, from cultivation to brewing techniques.',
      imageurl: 'https://storage.googleapis.com/app_darkendimg/products/smkontract.png' 
    },
   
  ];

const FeaturesPage = () => {
  return (
    <Container maxWidth="lg">
      {/* Hero Section (Fully Contained) */}
      <Box
        sx={{
          position: "relative",
          height: { xs: "400px", sm: "500px", md: "600px" }, // Responsive height
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end", // Align text to the right
          paddingRight: { xs: 2, sm: 5, md: 10 }, // Adjust padding for right-aligned text
          borderRadius: 2,
          overflow: "hidden",
          mt: 1
        }}
      >
        {/* Background Image Layer (Constrained to Container) */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/data-speedway/digi-assets.jpg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "#00bfff", // Fallback color
            filter: "brightness(1)", // Darkens for readability
            zIndex: -1
          }}
        />

        {/* Animated Text Box */}
       
      </Box>

      {/* Features Grid (Contained in Container) */}
      <Grid container spacing={4} justifyContent="center" mt={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
              <CardMedia
                component="img"
                height="200"
                image={feature.imageurl}
                alt={feature.title}
              />
              <CardContent>
                <Box display="flex" alignItems="center">
                  <CheckCircleOutlineIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="h6" fontWeight="bold">
                    {feature.title}
                  </Typography>
                </Box>
                <Typography variant="body2" mt={1} color="textSecondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FeaturesPage;
